const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.vale.forwoodsafety.com',
    APP_CLIENT_ID: '319fau4u2vpsad76e9fk40gae5',
    USER_TOKEN_URL: 'https://7nkt8rum26.execute-api.us-east-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://578l916ig0.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: ' admin-container-1.9.1',
    HOSTNAME: 'https://admin.testing.vale.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.testing.vale.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.testing.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.vale.forwoodsafety.com',
    WEBSOCKET: '/testing'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;